import {ApplicationConfig, LOCALE_ID} from '@angular/core';
import {provideRouter, withComponentInputBinding} from '@angular/router';

import {routes} from './app.routes';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";
import {GALLERY_CONFIG, GalleryConfig} from "ng-gallery";
import {DatePipe, DecimalPipe} from "@angular/common";
import {AuthenticationInterceptor} from "@app/authentication.interceptor";
import {LIGHTBOX_CONFIG, LightboxConfig} from "ng-gallery/lightbox";
import config from 'devextreme/core/config';

config({ licenseKey: "ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjc5NjBhZjE1LTJiMDQtMTFlNS04MGNjLTAwMTU1ZDYyNDcwMSIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.HmaJiv+gSzcq+wBTD6/1OBCVEsZGtFXz+P6Z4SZoG3j+oZeec+d6iycInT8f8b2eGBC6ElDNLZeGb5EHneHTrPgS2lStXA3kKg1L6OZJrV+KKA4/AxFENRdcjq76SsXi/TSQMQ==" });


/**
 * Application configuration object.
 */
export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes, withComponentInputBinding()), provideHttpClient(withInterceptorsFromDi()), provideAnimations(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de'
    },
    {
      provide: GALLERY_CONFIG,
      useValue: {
        autoHeight: false,
        imageSize: 'cover'
      } as GalleryConfig,
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: true,
        exitAnimationTime: 200
      } as LightboxConfig
    },
    DecimalPipe,
    DatePipe,
  ]
};
