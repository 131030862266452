import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from "@app/sites/login/login.service";


/**
 * Check if user is authenticated and token not expired
 */
export const authGuard = () => {
  const router = inject(Router);
  const loginService = inject(LoginService);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (loginService.authentication().token && loginService.authentication().expiration && new Date(loginService.authentication().expiration!) >= tomorrow) {
    return true;
  } else {
    router.navigate(['login']).then();
    return false;
  }
}
