import {Deferred} from 'devextreme/core/utils/deferred';
import {HttpClient, HttpParams} from '@angular/common/http';

export function sendRequestFactory(httpClient: HttpClient) {
  const URLENCODED = 'application/x-www-form-urlencoded';
  const CONTENT_TYPE = 'Content-Type';


  function assignResponseProps(xhrSurrogate: any, response: any) {
    const getResponseHeader = (name: string) => response.headers.get(name);

    const makeResponseText = () => {
      const body = 'error' in response ? response.error : response.body;
      return typeof body !== 'string' || String(getResponseHeader(CONTENT_TYPE)).startsWith('application/json')
        ? JSON.stringify(body)
        : body;
    };

    Object.assign(xhrSurrogate, {
      status: response.status,
      statusText: response.statusText,
      getResponseHeader,
      responseText: makeResponseText(),
    });

    return xhrSurrogate;
  }

  function getAcceptHeader(dataType: string, accepts: any) {
    const fallback = ',*/*;q=0.01';

    if (dataType && accepts && accepts[dataType]) {
      return `${accepts[dataType]}${fallback}`;
    }

    const dataTypeMap: Record<string, string> = {
      json: 'application/json, text/javascript',
      text: 'text/plain',
      xml: 'application/xml, text/xml',
      html: 'text/html',
      script: 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript',
    };

    return dataType in dataTypeMap ? dataTypeMap[dataType] + fallback : '*/*';
  }

  return (options: any) => {
    const d = Deferred();

    const method = (options.method || 'get').toLowerCase();
    const isGet = method === 'get';
    const { headers = {}, data, upload, beforeSend, xhrFields, contentType } = options;

    if (!headers.Accept) {
      headers.Accept = getAcceptHeader(options.dataType, options.accepts);
    }

    if (!upload && !isGet && !headers[CONTENT_TYPE]) {
      headers[CONTENT_TYPE] = contentType || `${URLENCODED};charset=utf-8`;
    }

    let params: HttpParams | undefined;
    let body: string | undefined;

    if (isGet) {
      params = data;
    } else {
      if (!upload && typeof data === 'object' && headers[CONTENT_TYPE].startsWith(URLENCODED)) {
        const bodyParams = new HttpParams({ fromObject: data });
        body = bodyParams.toString();
      } else {
        body = data;
      }
    }

    const xhrSurrogate: any = {};

    if (beforeSend) {
      beforeSend(xhrSurrogate);
    }

    httpClient
      .request(method, options.url, {
        params,
        body,
        responseType: options.dataType,
        headers,
        withCredentials: xhrFields?.withCredentials,
        observe: 'response',
      })
      .subscribe({
        next: (response) => {
          d.resolve(response.body, 'success', assignResponseProps(xhrSurrogate, response));
        },
        error: (response) => {
          d.reject(assignResponseProps(xhrSurrogate, response), 'error');
        },
      });

    return d.promise();
  };
}
