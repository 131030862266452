import {inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {BehaviorSubject, catchError, Observable, throwError} from 'rxjs';
import {Router} from "@angular/router";
import {LoginService} from "@app/sites/login/login.service";
import {Auth} from "@app/models/auth";
import {environment} from "../environments/environment";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private loginService = inject(LoginService);
  private router = inject(Router);
  private isRefreshing = false;
  tokenSubject: BehaviorSubject<Auth | null> = new BehaviorSubject<Auth | null>(null);

  /**
   * Intercepts the HTTP requests and adds the authorization header with the access token.
   * It attaches the authorization header to the request and forwards it.
   * If there's an error with status code 401 (Unauthorized), it refreshes the authentication token and retries the request.
   * If there's any other error, it throws the error.
   * @param {HttpRequest<unknown>} request - The intercepted request.
   * @param {HttpHandler} next - The next HTTP handler.
   * @returns {Observable<HttpEvent<unknown>>} An observable emitting the HTTP event.
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    if (request.url.includes('assets/data/notifications')) {
      return next.handle(request);
    }
    const requestHost = new URL(request.url).host;
    const apiUrlHost = new URL(environment.apiUrl).host;

    if (requestHost !== apiUrlHost) {
      return next.handle(request);
    }

    const token = localStorage.getItem('token');
    const attachedAuthRequest = this.attachAuthorizationHeader(request, token);

    return next.handle(attachedAuthRequest).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !request.url.includes('login')) {
          this.router.navigate(['login']);
        }
        return throwError(() => error);
      })
    );

  }

  /**
   * Attaches the authorization header with the access token to the provided request.
   * If the access token is not available, it returns the original request unchanged.
   * @param {HttpRequest<any>} request - The request to attach the authorization header to.
   * @param tokenRes
   * @returns {HttpRequest<any>} The modified request with the authorization header, or the original request if the access token is not available.
   */
  private attachAuthorizationHeader(request: HttpRequest<any>, token: string | null) {
    if (!token) return request;

    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}
