import {Component} from '@angular/core';

import {RouterOutlet} from '@angular/router';
import {DxButtonModule, DxTextBoxModule} from "devextreme-angular";
import deMessages from "devextreme/localization/messages/de.json";
import {loadMessages, locale} from "devextreme/localization";
import Number_box, {Properties as NumberBoxProperties} from "devextreme/ui/number_box";
import File_uploader, {Properties as FileUploaderProperties} from "devextreme/ui/file_uploader";
import Load_Panel, {Properties as LoadPanelProperties} from "devextreme/ui/load_panel";

// @ts-ignore
import devextremeAjax from 'devextreme/core/utils/ajax';
import {sendRequestFactory} from "@app/functions/ng-http-client-helper";
import {HttpClient} from '@angular/common/http';


@Component({
    selector: 'app-root',
    imports: [RouterOutlet, DxTextBoxModule, DxButtonModule],
    templateUrl: './app.component.html'
})
export class AppComponent {

  //#region Variables
  /**
   * Represents the page title.
   */
  title = 'detect';

  //#endregion

  //#region Lifecycle

  /**
   * Constructs a new instance of the MyClass class.
   */
  constructor(httpClient: HttpClient) {
    loadMessages(deMessages);
    locale(navigator.language);
    devextremeAjax.inject({sendRequest: sendRequestFactory(httpClient)});
    Number_box.defaultOptions<NumberBoxProperties>({
      options: {
        step: 0,
        showClearButton: true,
      }
    });

    File_uploader.defaultOptions<FileUploaderProperties>({
      options: {
        selectButtonText: 'Choose files',
        labelText: 'or drag & drop here'
      }
    });

    Load_Panel.defaultOptions<LoadPanelProperties>({
      options: {
        shadingColor: 'shadingColor="rgba(0,0,0,0.4)"'
      }
    })

  }


  //#endregion
}
